import axios from "axios";
import {User as UserType} from "../context/index"

// TODO
export type User = {
  accessToken: string | null;
  user: UserType
};

export const getReceivedTransactions = async (user: User) => {
  // This makes the request for each code
  const request = (item: string) =>
    new Promise((resolve, reject) => {
      const token = user?.accessToken;
      const url = process.env.REACT_APP_BASE_URL + `/transaction?code=${item}`;
      const options = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = axios.get(url, options);
        resolve(res);
      } catch (error : any) {
        if (error.response.data.msg === "jwt expired") {
          localStorage.removeItem("user");
          window.location.reload();
        }
      }
    });

  const receivedTransCodes = user?.user?.receivedTransactions;
  const requests = receivedTransCodes?.map((item: string, index: number) =>
    request(item),
  );

  if (requests) {
    let resultPromise = await Promise.all(requests);
    let res = resultPromise.map((item: any) => item.data.data);
    res = res.filter(item => item !== undefined);
    res = res.map(item => item[0])
    return res.flat();
  } else {
    return []
  }
};

export const getSentTransactions = async (user: User) => {
  // This makes the request for each code
  const request = (item: string) =>
    new Promise((resolve, reject) => {
      const token = user?.accessToken;
      const url = process.env.REACT_APP_BASE_URL + `/transaction?code=${item}`;
      const options = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = axios.get(url, options);
        resolve(res);
      } catch (error : any) {
        if (error.response.data.msg === "jwt expired") {
          localStorage.removeItem("user");
          window.location.reload();
        }
      }
    });

  const sentTransCodes = user?.user?.sentTransactions;
  const requests = sentTransCodes?.map((item: string, index: number) =>
    request(item),
  );
  if (requests) {

    let resultPromise = await Promise.all(requests);
    let res = resultPromise.map((item: any) =>item.data.data);
    res = res.filter(item => item !== undefined);
    res = res.map(item => item[0])
    return res.flat();
  } else {
    return []
  }
};
