import React from "react";
// components
import Overview from "./Overview";
import Infos from "./Infos";
import Footer from "./Footer";

const Entry : React.FC = () : JSX.Element => {

  return (
    <>
      <Overview />
      <Infos />
      <Footer />
    </>
  )
}

export default Entry;
