import React from "react";
import { Context } from "../../context";
// components
import PageTitle from "../utils/PageTitle";
import GetUser from "../utils/GetUser";
import AdminTransactions from "./Admin";
import Section from "./Section";
// utils
import {
  getReceivedTransactions,
  getSentTransactions,
  User
} from "../../utils/getTransactions";
// styles
import {
  SOptionsContainer,
  SReceived,
  SSent,
  STransaction,
} from "./styles";
// types
import { TTransaction } from "./types"


const Transaction: React.FC = (): JSX.Element => {
  const initialTransactions: { sent: TTransaction; received: TTransaction } = {
    sent: [] as unknown as TTransaction,
    received: [] as unknown as TTransaction,
  };

  const [isSent, setIsSent] = React.useState(false);
  const [transactions, setTransactions] = React.useState(initialTransactions);

  const { user, isAdminMode } = React.useContext(Context);
  let accessToken = localStorage.getItem("user")
  accessToken = JSON.parse(accessToken!)

  // Gets the transactions and sets them in order
  // to display them for the user
  React.useEffect(() => {
    (async function () {
      const data : User = {
        user: user,
        accessToken: accessToken
      }
      const received = await getReceivedTransactions(data);
      const sent = await getSentTransactions(data);
      setTransactions({
        sent: sent as unknown as TTransaction,
        received: received as unknown as TTransaction,
      });
    })();
  }, [user, accessToken]);

  if (isAdminMode) return <AdminTransactions />
  else return (
    <STransaction>
      <GetUser />
      <PageTitle text="Transactions" />
      <SOptionsContainer>
        <SReceived
          optional={{ isSent: isSent }}
          onClick={() => setIsSent(false)}
        >
          Received
        </SReceived>
        <SSent optional={{ isSent: isSent }} onClick={() => setIsSent(true)}>
          Sent
        </SSent>
      </SOptionsContainer>
      <Section
        title="Active"
        elements={
          isSent
            ? transactions.sent.filter((item) => item.state !== "received")
            : transactions.received.filter((item) => item.state !== "received")
        }
      />
      <Section
        title="History"
        elements={
          isSent
            ? transactions.sent.filter((item) => item.state === "received")
            : transactions.received.filter((item) => item.state === "received")
        }
      />
    </STransaction>
  );
};



export default Transaction;
