import React from "react";
// components
import Header from "./Header";

type Props = {
  children: JSX.Element;
}

const Layout: React.FC<Props> = ({children}) : JSX.Element => {
  return (
    <>
      <Header />
      {children}
    </>
  )
}

export default Layout;