import styled from "styled-components";

export const STransaction = styled.div`
  padding: 1em;
`;

export const SOverview = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  padding: 1em;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const SOverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
`;

export const STransactionCodeTitle = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 500;
`;

export const STransactionCodeValue = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-weight: 600;
  margin-top: 3px;
`;

export const STransactionMessage = styled(STransactionCodeValue)`
  font-weight: 400;
  margin-bottom: 1.5em;
`;
  
export const STransactionMessageTitle =styled(STransactionCodeTitle)`
`;

export const STransactionState = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  padding: 5px 10px;
  border-radius: 15px;
  height: fit-content;

  & > p {
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const SAmountTitle = styled.div`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 500;
`;

export const SAmountValue = styled.div`
  color: ${(props) => props.theme.colors.main};
  font-weight: 600;
  font-size: 1.3em;
  margin-top: 3px;
  margin-bottom: 1em;
`;

export const SSectionSenderContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 0.5em;
`;

export const SFrom = styled.p`
  color: ${(props) => props.theme.colors.dark};
`;

export const SSectionSender = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-weight: 500;
`;

export const SHistory = styled.div`
  padding: 2em 0 1em 0;
`;

export const SHistoryTitle = styled.p`
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 1em;
`;

export const SRequestWidrawal = styled.div`
  display: flex;
  gap: 0.5em;
  padding: 1.3em;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: ${(props) => props.theme.borderRadius};
  color: #fff;
`;

export const SEndTransaction = styled.div`
  display: flex;
  gap: 0.5em;
  padding: 1.3em;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: ${(props) => props.theme.borderRadius};
  color: #fff;
  justify-content: center;
  align-items: center;
`;
