import axios from "axios";
import { User } from "../../context";
import { TTransaction } from "./types";

export const fetchTransactions = async () => {
  const url = process.env.REACT_APP_BASE_URL + "/transaction";
  let token = localStorage.getItem("user");
  token = token ? JSON.parse(token) : "";
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(url, options);
  return response.data.data;
};

export const takeStep = async (
  target: string,
  transaction: TTransaction,
  user: User,
) => {
  const url =
    process.env.REACT_APP_BASE_URL +
    `/transaction/take-step?target=${target}&code=${transaction.code}`;
  let token = localStorage.getItem("user");
  token = token ? JSON.parse(token) : "";
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {
    handlerID: user._id,
    handlerEmail: user.email,
    time: Date.now(),
  };
  const response = await axios.put(url, data, options);
  return response.data.data;
};
