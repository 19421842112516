import React, { SetStateAction } from "react";
// react-icons
import {
  FiArrowUpRight,
  FiArrowDownRight,
  FiAlertCircle,
  FiShare2,
} from "react-icons/fi";
import { HiOutlineCash } from "react-icons/hi";
// react-router-dom
import { useNavigate } from "react-router-dom";
// types
import { OptionProps } from "./types";
// styles
import {
  SOption,
  SOptionContainer,
  SOptionText,
  SStart,
  SHeader,
  SGreeting,
  SSubGreeting,
  SEmail,
  SContainer,
  SProcessingTransaction,
  SAdminPendingTransaction,
  SAdminPendingTransactionLabel,
  SOptionA,
} from "./styles";
import { Context } from "../../context";
import GetUser from "../utils/GetUser";
// utils
import {
  getReceivedTransactions,
  User,
} from "../../utils/getTransactions";
import { fetchTransactions } from "../Transactions/utils";
import { TTransaction } from "../Transactions/types";
import { share_text } from "../../data/texts/entry";

const Start: React.FC = (): JSX.Element => {
  const initialReceivedT: SetStateAction<any[]> = [];

  const initialAdminTransactions: TTransaction[] = [];

  const [receivedT, setReceivedT] = React.useState(initialReceivedT);
  const [processing, setProcessing] = React.useState([]);
  const [adminTransactions, setAdminTransactions] = React.useState(
    initialAdminTransactions,
  );

  const navigate = useNavigate();

  const { user, isAdminMode, setAdminMode } = React.useContext(Context);
  let accessToken = localStorage.getItem("user");
  accessToken = JSON.parse(accessToken!);

  // Filtering the received transactions for those
  // that are not yet finalized
  React.useEffect(() => {
    if (Object.keys(receivedT).length !== 0) {
      const process: any[] = receivedT.filter(
        (item) => item.state !== "received",
      );
      setProcessing(process as unknown as never[]);
    }
  }, [receivedT]);

  React.useEffect(() => {
    // Get the received transaction
    (async function () {
      if (user) {
        const data: User = {
          user: user,
          accessToken: accessToken,
        };
        const res = await getReceivedTransactions(data);
        setReceivedT(res);
      }
    })();
  }, [user, accessToken]);

  React.useEffect(() => {
    (async function () {
      if (isAdminMode) {
        const response = await fetchTransactions();
        setAdminTransactions(response);
      }
    })();
  }, [isAdminMode]);

  const pendingTransactionLength =
    adminTransactions &&
    adminTransactions.filter((item) => item.state !== "received").length;

  const sProcessing = processing.length > 1 ? "s" : "";
  const sPending = pendingTransactionLength > 1 ? "s" : "";

  const onProcessingTransactionsClck = () => {
    setAdminMode(false);
    navigate("/transactions");
  };

  const onPendingTransactionsClick = () => {
    setAdminMode(true);
    navigate("/transactions");
  };

  return (
    <SStart optional={{ isProcessing: processing.length > 0 }}>
      <GetUser />
      <SHeader>
        <SGreeting>
          {user.firstname && "Hello " + user?.firstname + ","}
        </SGreeting>
        <SEmail>{user.email && user.email}</SEmail>
        <SSubGreeting>
          Welcome back to Konekte, where value can be shared!
        </SSubGreeting>
        {isAdminMode && (
          <SAdminPendingTransaction onClick={onPendingTransactionsClick}>
            <SAdminPendingTransactionLabel>
              <p>Adm</p>
            </SAdminPendingTransactionLabel>
            <p>
              {(pendingTransactionLength || 0) +
                " pending transaction" +
                sPending}
            </p>
          </SAdminPendingTransaction>
        )}
        {processing.length > 0 && (
          <SProcessingTransaction onClick={onProcessingTransactionsClck}>
            <HiOutlineCash color="white" size="1.5em" />
            <p>
              {processing.length +
                " processing transfer" +
                sProcessing +
                ". Click to track!"}
            </p>
          </SProcessingTransaction>
        )}
      </SHeader>
      <SContainer>
        <SOptionContainer>
          <Option
            logo={<FiArrowUpRight size={30} />}
            text="Send instantly"
            // main={true}
            onClick={() => {
              navigate("/sending");
            }}
          />
          <Option
            logo={<FiArrowDownRight size={30} />}
            text="Track and get"
            onClick={() => {
              navigate("/track");
            }}
          />
        </SOptionContainer>
        <SOptionContainer>
          <Option
            logo={<FiAlertCircle size={30} />}
            text="User agreement"
            onClick={() => {
              navigate("/agreement");
            }}
          />
          <SOption>
            <FiShare2 size={30} />
            <SOptionA
              href={`whatsapp://send?text=${share_text}`}
              data-action="share/whatsapp/share"
            >
              Share to WhatsApp
            </SOptionA>
          </SOption>
        </SOptionContainer>
      </SContainer>
    </SStart>
  );
};

const Option: React.FC<OptionProps> = (props): JSX.Element => {
  return (
    <SOption optional={{ main: props.main }} onClick={props.onClick}>
      {props.logo}
      <SOptionText optional={{ main: props.main }}>
        {props.text}
      </SOptionText>
    </SOption>
  );
};

export default Start;
