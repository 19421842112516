import React from "react";
import { useNavigate } from "react-router-dom"; // styled-components
import styled from "styled-components";
// components
import PageTitle from "../utils/PageTitle";
import InputUnit from "../utils/InputField";
import Button from "../utils/Button";
import GetUser from "../utils/GetUser";
// data
import { making_transaction } from "../../data/texts/entry";
// react-icons
import { HiExternalLink } from "react-icons/hi";
// types
import { meetConditionTransaction } from "../../utils";
import { Context } from "../../context";
// utils
import { CheckoutProps } from "../Checkout/types";

export type InitialValueTransaction = {
  email?: string;
  amount?: number;
  message?: string;
};

const Sending: React.FC = (): JSX.Element => {
  const initialValues: InitialValueTransaction = {
    email: "",
    amount: 0,
    message: "",
  };

  const [data, setData] = React.useState(initialValues);

  const { user } = React.useContext(Context);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }, [navigate, user]);

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const cond = meetConditionTransaction(data);
    if (!cond) {
    } else {
      const state : CheckoutProps = {
        data: data
      };
      console.log(state);
      navigate("/checkout", {state: state});
    }
  };

  return (
    <SSending>
      <GetUser />
      <PageTitle text="Transaction info" />
      <SForm onSubmit={handleSubmit}>
        {making_transaction.map((item, index) => {
          return (
            <InputUnit
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              placeholder={item.placeholder}
              error_message={item.error_message}
              id={item.id}
              pattern={item.pattern}
              stateSetter={setData}
              type={item.type}
              state={data}
              validator_func={item.validator_func}
            />
          );
        })}
        <Button
          agreement_text="By clicking, you accept the conditions and our user agreemement."
          input_id="start-checkout"
          input_name="start-checkout"
          input_type="checkbox"
          input_value="1"
          submit_value="Checkout"
          link_icon={<HiExternalLink size="1.3em" />}
          condition_met={() => meetConditionTransaction(data)}
          // callback={res}
        />
      </SForm>
    </SSending>
  );
};

const SSending = styled.div`
  padding: 1em;
`;

const SForm = styled.form`
  gap: 2em;
  display: flex;
  flex-direction: column;
`;

export default Sending;
