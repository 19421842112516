import styled from "styled-components";

export const SSendVerdict = styled.div`
  padding: 1em 1em 2em 1em;
  /* background-color: red; */
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SVisualIndicator = styled.div`
  /* background-color: red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type StyleProps = {
  optional?: {
    success: boolean;
  };
};

export const SVisualIndicatorCircle = styled.div<StyleProps>`
  border: 1px solid
    ${(props) =>
      props.optional?.success
        ? props.theme.colors.main
        : "#FF8600"};
  height: 7em;
  width: 7em;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SVerdict = styled.p`
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 0.5em;
`;

export const SPBold = styled.p`
  font-weight: 600;
`;

export const SInfoBox = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1.2em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;

export const SCode = styled.div`
  padding: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.white};
`;

export const SErrorMessage = styled.div`
  background-color: ${props => props.theme.colors.gray};
  padding: 1.5em 1em;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1em;

  & > p {
    line-height: 1.5em;
    color: ${props => props.theme.colors.dark}
  }
`;

export const STryAgain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  padding: 1.2em;
  background-color: ${(props) => props.theme.colors.yellow};
  border-radius: ${props => props.theme.borderRadius};
`;