import { making_transaction } from "../data/texts/entry";
import { InitialValueTransaction } from "../views/Sending";
import { InitialValueFormAuth } from "../views/utils/InputField";

export const currencyDisplayer = (amount?: number, currency?: string) => {
  if (amount) {
    if (!currency) currency = "USD";
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: currency,
    });
  }
};

export const parseTime = (timestamp: number): string => {
  const date = new Date(timestamp);

  const hours = date.getHours();
  const validHours = hours < 10 ? "0" + hours : hours;

  const minutes = date.getMinutes();

  const validMinutes = minutes < 10 ? "0" + minutes : minutes;

  return validHours + ":" + validMinutes;
};

export const meetConditionLogin = (email?: string, password?: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validEmail = re.test(email!);

  return validEmail && password?.length! >= 8;
};

export const meetConditionSignUp = (obj: InitialValueFormAuth) => {
  // firstname, lastname
  let nameRe = /[A-Za-z-]/;
  const validFirstName = nameRe.test(obj.firstname!);
  const validLastName = nameRe.test(obj.lastname!);

  // email
  const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validEmail = emailRe.test(obj.email!);

  // password
  const firstPasswordValidator = obj.password1 === obj.password2;
  const passRe = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;
  const secondPasswordValidator = passRe.test(obj.password1!);

  return (
    validFirstName &&
    validLastName &&
    validEmail &&
    firstPasswordValidator &&
    secondPasswordValidator
  );
};

export const meetConditionTransaction = (
  obj: InitialValueTransaction,
): boolean | undefined => {
  // email
  const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validEmail = emailRe.test(obj.email!);

  // amount
  const amountObj = making_transaction.filter(
    (item) => item.id === "amount",
  )[0];
  const func = amountObj.validator_func;
  const amount = (obj.amount ||
    0) as unknown as React.ChangeEvent<HTMLInputElement>;
  const validAmount = func && func(amount);

  // message
  const messageRe = /^.{1,64}$/;
  const validMessage = messageRe.test(obj.message!);

  return validMessage && validAmount && validEmail;
};
