import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  borderRadius: '15px',
  titleSize: '1.1em',

  colors: {
    main: "rgba(39, 24, 126, 1)",
    secondary: "rgba(255, 134, 0, 1)",
    background: "rgba(245, 246, 251, 0.3)",
    dark: "rgba(0, 0, 0, 0.5)",
    gray: "rgba(217, 217, 217, 0.25)",
    white: "rgba(255, 255, 255, 1)",
    light_blue: "rgba(241, 242, 246, 1)",
    red: "rgb(255, 0, 0)",
    green: "rgb(119, 255, 0)",
    yellow: "rgb(255, 134, 0)",
  }
}

export { theme }