import React from "react";
// styled-components
import styled from "styled-components";
// utils
import { parseTime } from "../../utils";
// types 
import { Step } from "./types";


const StepUnit: React.FC<{ item: Step }> = ({ item }): JSX.Element => {
  return (
    <SStep>
      <SParsedTime>{parseTime(item.time)}</SParsedTime>
      <STransactionType>{item.type}</STransactionType>
    </SStep>
  );
};

const SStep = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1.3em 1em;
  margin-bottom: 1em;
  display: flex;
  gap: 0.5em;
`;

const SParsedTime = styled.p`
  font-size: 0.9em;
  font-weight: 600;
  color: ${(props) => props.theme.colors.main};
`;

const STransactionType = styled.p`
  font-size: 0.9em;
`;



export default StepUnit;