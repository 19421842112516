import styled from "styled-components";
import { HiOutlineXCircle } from "react-icons/hi";

const ErrorBox: React.FC<{txt: string}> = ({txt}): JSX.Element => {
  return (
    <SBadInputs>
      <HiOutlineXCircle size={"1.5em"} />
      <SPBadInputs>{txt}</SPBadInputs>
    </SBadInputs>
  );
};

const SBadInputs = styled.div`
  background-color: rgba(234, 67, 53, 0.15);
  padding: 1em;
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  gap: 5px;
  margin-bottom: 2em;
  justify-content: space-between;
  color: red;
`;

const SPBadInputs = styled.p`
  width: 90%;
`;

export default ErrorBox;
