import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
  }

  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
  }

  body {
    margin: 0;
    width: 100vw;
    background-color: ${props => props.theme.colors.white};
  }
`;

export { GlobalStyles }