import React from "react";
// types
import { SectionProps } from "./types";
// styles
import { SSectionTransactions, SSectionTransactionsTitle } from "./styles";
// component
import SectionUnit from "./TransactionUnit";
import SectionUnitAdmin from "./TransactionUnitAdmin";
import { Context } from "../../context";

const Section: React.FC<SectionProps> = (props): JSX.Element => {
  const { isAdminMode } = React.useContext(Context);

  const initialSelectedUnit: number = NaN;
  const [selectedUnit, setSelectedUnit] = React.useState(initialSelectedUnit);

  const onSectionUnitClick = (index: number) => {
    if (selectedUnit === index) setSelectedUnit(NaN)
    else setSelectedUnit(index)
  }

  if (props.elements?.length === 0) {
    return <></>;
  } else {
    return (
      <SSectionTransactions>
        <SSectionTransactionsTitle>{props.title}</SSectionTransactionsTitle>
        {props.elements?.map((item, index) =>
          isAdminMode ? (
            <SectionUnitAdmin
              key={index}
              element={item}
              isSelected={selectedUnit === index}
              onClick={onSectionUnitClick}
              ndx={index}
            />
          ) : (
            <SectionUnit element={item} key={index} />
          ),
        )}
      </SSectionTransactions>
    );
  }
};

export default Section;
