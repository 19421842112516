import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// react-icons
import { FiCheck, FiX } from "react-icons/fi";
// types
import { TLocation, TransactionDetails } from "./types";
// utils
import { currencyDisplayer } from "../../utils";
import { transaction_error } from "../../data/texts/entry";
// styles
import {
  SCode,
  SErrorMessage,
  SInfoBox,
  SPBold,
  SSendVerdict,
  STryAgain,
  SVerdict,
  SVisualIndicator,
  SVisualIndicatorCircle,
} from "./styles";
import { defineAmounts } from "../../utils/defineAmounts";

const SendVerdict: React.FC = (): JSX.Element => {

  // const copyToClipBoard = async (text: string) => {
  //   try {
  //     await navigator.clipboard.writeText(text);
  //     console.log("Copied");
  //   } catch (err) {
  //     // TODO refactor
  //   }
  // };

  const navigate = useNavigate();

  const location = useLocation() as TLocation;
  const state = location.state;
  let data;
  if (state) {
    const amounts = defineAmounts(state.amount);
    console.log(amounts)
    data = {
      code: state.code,
      amountReceived: currencyDisplayer(amounts.amountReceived, state.currency),
      amountSpent:currencyDisplayer(amounts.amountSpent, state.currency),
      transferFee: currencyDisplayer(amounts.transferFee, state.currency),
      cardFee: currencyDisplayer(amounts.cardFee, state.currency),
      totalCost: currencyDisplayer(amounts.totalCost, state.currency),
      currency: state.currency,
      receiverEmail: state.receiverEmail,
    };
  }

  const verdictText = state !== null ? "Successful" : "Failed";

  return (
    <SSendVerdict>
      <SVisualIndicator>
        <SVisualIndicatorCircle
          optional={{ success: state !== null }}
        >
          {state ? (
            <FiCheck
              size="2em"
              color="rgba(39, 24, 126, 1)"
            />
          ) : (
            <FiX size="2em" color="#FF8600" />
          )}
        </SVisualIndicatorCircle>
        <SVerdict>{verdictText}</SVerdict>
      </SVisualIndicator>
      {state !== null ? (
        <div>
          <DetailBox
            type="Receiver"
            value={data?.receiverEmail}
          />
          <DetailBox type="Amount to be received" value={data?.amountReceived} />
          <DetailBox
            type="Amount spent"
            value={data?.amountSpent}
          />
          <DetailBox
            type="Transfer fee (2.99%)"
            value={data?.transferFee}
          />
          <DetailBox
            type="Card fee (2.90% + $0.3)"
            value={data?.cardFee}
          />
          <DetailBox
            type="Total cost"
            value={data?.totalCost}
          />
          <SCode onClick={() => navigate('/start')}>
            <p>Go back</p>
          </SCode>
        </div>
      ) : (
        <div>
          <SErrorMessage>
            <p>{transaction_error}</p>
          </SErrorMessage>
          <STryAgain
            onClick={() =>
              navigate("/sending", { replace: true })
            }
          >
            <SPBold>Try again</SPBold>
          </STryAgain>
        </div>
      )}
    </SSendVerdict>
  );
};

const DetailBox: React.FC<TransactionDetails> = (
  props,
): JSX.Element => {
  return (
    <SInfoBox>
      <SPBold>{props.type}</SPBold>
      <p>{props.value}</p>
    </SInfoBox>
  );
};

export default SendVerdict;
