import React from "react";
// types
import {
  SectionUnitAdminProps,
  SelectedPartProps,
  StateTextProps,
} from "./types";
// styles
import {
  SSectionUnit,
  SSectionUnitCode,
  SSectionUnitHeader,
  SSectionUnitStatusContainer,
  SSectionAmount,
  SSectionSenderContainer,
  SSectionSender,
  SFrom,
  SSelectedPart,
  SStateText,
  SStateTextContainer,
  SDetailsContainer,
  SDetails,
  SBold,
  SNormalText,
  SActionButton,
  SActionButtonContainer,
  SWaitingForWithdrawalMessage,
  SConfirmText,
  // SReceiptInput,
} from "./styles";
// utils
import { currencyDisplayer } from "../../utils";
import { takeStep } from "./utils";
import { Context } from "../../context";

const SectionUnitAdmin: React.FC<SectionUnitAdminProps> = (
  props,
): JSX.Element => {
  const amount = currencyDisplayer(
    props.element.amount!,
    props.element.currency,
  );

  const handleClick = () => {
    props.onClick(props.ndx);
  };

  return (
    <SSectionUnit onClick={handleClick}>
      <SSectionUnitHeader>
        <SSectionUnitCode>{props.element.code}</SSectionUnitCode>
        <SSectionUnitStatusContainer>
          <p>{props.element.state}</p>
        </SSectionUnitStatusContainer>
      </SSectionUnitHeader>
      <SSectionAmount>{amount}</SSectionAmount>
      <SSectionSenderContainer>
        <SFrom>from</SFrom>
        <SSectionSender>{props.element.senderFullName}</SSectionSender>
      </SSectionSenderContainer>
      {props.isSelected && <SelectedPart item={props.element} />}
    </SSectionUnit>
  );
};

const SelectedPart: React.FC<SelectedPartProps> = (props): JSX.Element => {
  const sentState = props.item.history.states[0];
  const availableState = props.item.history.states[1];
  const requestedState = props.item.history.states[2];
  const handlingState = props.item.history.states[3];
  const receivedState = props.item.history.states[4];

  const sentDate = sentState
    ? new Date(sentState.serverTime).toUTCString()
    : "";
  const availableDate = availableState
    ? new Date(availableState.serverTime).toUTCString()
    : "";
  const requestedDate = requestedState
    ? new Date(requestedState.serverTime).toUTCString()
    : "";
  const handlingDate = handlingState
    ? new Date(handlingState.serverTime).toUTCString()
    : "";
  const receivedDate = receivedState
    ? new Date(receivedState.serverTime).toUTCString()
    : "";

  // console.log(props.item);

  const transactionStates = [
    "sent",
    "available",
    "requested",
    "handling",
    "received",
  ];

  const defineNextStep = (state?: string): string => {
    let stateIndex;
    if (transactionStates.includes(state!))
      stateIndex = transactionStates.indexOf(state!);
    else stateIndex = NaN;

    const nextState = stateIndex + 1;
    return transactionStates[nextState];
  };

  const nextStep = defineNextStep(props.item.state);

  const { user } = React.useContext(Context);

  const handleButtonClick = async () => {
    const result = await takeStep(nextStep, props.item, user);
    console.log(result);
  };

  console.log(props.item)

  return (
    <SSelectedPart>
      <SSectionSenderContainer>
        <SFrom>to</SFrom>
        <SSectionSender>{props.item.receiverEmail}</SSectionSender>
      </SSectionSenderContainer>
      {sentState && (
        <StateText
          date={sentState.state + " on " + sentDate}
          text={props.item.senderEmail}
        />
      )}
      {availableState && (
        <StateText
          date={availableState.state + " on " + availableDate}
          text={availableState.handlerEmail}
        />
      )}
      {requestedState ? (
        <div>
          <StateText
            date={requestedState.state + " on " + requestedDate}
            text={requestedState.handlerEmail}
          />
          <SDetailsContainer>
            <DetailsComponent
              title="Conversion rate"
              value={props.item.sellingRate}
              add="RUB/$"
            />
            <DetailsComponent
              title="To be transfered"
              value={props.item.sellingRate! * props.item.amount!}
              add="RUB"
            />
            <DetailsComponent
              title="Bank"
              value={props.item.history.states[2].requestDetails?.bank}
              add=""
            />
            <DetailsComponent
              title="Phone number"
              value={props.item.history.states[2].requestDetails?.phoneNumber}
              add=""
            />
          </SDetailsContainer>
        </div>
      ) : (
        <></>
      )}
      {handlingState && (
        <StateText
          date={handlingState.state + " on " + handlingDate}
          text={handlingState.handlerEmail}
        />
      )}
      {receivedState && (
        <StateText
          date={receivedState.state + " on " + receivedDate}
          text={receivedState.handlerEmail}
        />
      )}
      {nextStep !== undefined && nextStep !== "requested" && (
        <ActionButton text={nextStep} action={handleButtonClick} />
      )}
      {nextStep === "requested" && (
        <SWaitingForWithdrawalMessage>
          {"> Waiting for withdrawal request..."}
        </SWaitingForWithdrawalMessage>
      )}
    </SSelectedPart>
  );
};

const StateText: React.FC<StateTextProps> = (props): JSX.Element => {
  return (
    <SStateTextContainer>
      <SStateText>{">"}</SStateText>
      <div>
        <SStateText>{"" + props.date}</SStateText>
        <SStateText>{"By: " + props.text}</SStateText>
      </div>
    </SStateTextContainer>
  );
};

type DetailsTextProps = {
  title: string;
  value?: string | number;
  add?: string | number;
};

const DetailsComponent: React.FC<DetailsTextProps> = (props): JSX.Element => {
  return (
    <SDetails>
      <SBold>{props.title + ":"}</SBold>
      <SNormalText>{props.value + " " + props.add}</SNormalText>
    </SDetails>
  );
};

type ActionButtonProps = {
  bg?: string;
  text?: string;
  action: () => void;
};

const ActionButton: React.FC<ActionButtonProps> = (props): JSX.Element => {
  const [isConfirm, setConfirm] = React.useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setConfirm(true);
  };

  return (
    <>
      {isConfirm && <SConfirmText>
        By confirming, the action will automatically take place, please make
        sure that everything is ok.
      </SConfirmText>}
      <SActionButtonContainer>
        {/* TODO We don't have yet the storage for the FTP */}
        {/* {props.text === "received" && (
          <SReceiptInput 
            type={"file"}
          />
        )} */}
        {!isConfirm && (
          <SActionButton
            optional={{ bg: props.bg }}
            onClick={(e) => handleClick(e)}
          >
            <p>{`Set ${props.text}`}</p>
          </SActionButton>
        )}
        {isConfirm && (
          <SActionButton
            optional={{ bg: props.bg }}
            onClick={() => props.action()}
          >
            <p>Confirm</p>
          </SActionButton>
        )}
      </SActionButtonContainer>
    </>
  );
};

export default SectionUnitAdmin;
