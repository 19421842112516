import React from "react";
// styled-components
import styled from "styled-components";

type PageTitleProps = {
  text: string;
}

const PageTitle : React.FC<PageTitleProps> = ({text}) : JSX.Element => {
  
  return (
    <SPageTitleContainer>
      <SPageTitle>{text}</SPageTitle>
    </SPageTitleContainer>
  )
}

const SPageTitleContainer = styled.div`
  padding: 2em 0 2em 0;
`;

const SPageTitle = styled.p`
  font-size: 1.3em;
  font-weight: 500;
`;

export default PageTitle;