import styled from "styled-components";
import { StyleProps } from "./types";

export const SOption = styled.div<StyleProps>`
  height: 20vh;
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.optional?.main
      ? props.theme.colors.light_blue
      : props.theme.colors.light_blue};
`;

export const SOptionText = styled.p<StyleProps>`
  font-size: 1em;
  margin-top: 0.5em;
  max-width: 60%;
  text-align: center;
  color: ${(props) => (props.optional?.main ? "#fff" : "#000")};
`;

export const SOptionA = styled.a`
  font-size: 1em;
  margin-top: 0.5em;
  max-width: 60%;
  text-align: center;
  text-decoration: none;
  color: #000;
`;

type StartStyleProps = {
  optional?: {
    isProcessing: boolean;
  };
};

export const SStart = styled.div<StartStyleProps>``;

export const SHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 3vh 1em 10vh 1em;
  background-color: ${(props) => props.theme.colors.main};
`;

export const SGreeting = styled.p`
  font-size: 2.1em;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
`;

export const SSubGreeting = styled.p`
  margin-top: 1em;
  color: ${(props) => props.theme.colors.background};
  font-size: 1em;
`;

export const SEmail = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: 1em;
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1em 3em 1em;
  margin-top: -5vh;
`;

export const SOptionContainer = styled.div`
  display: flex;
  gap: 1em;
`;

export const SProcessingTransaction = styled.div`
  display: flex;
  gap: 1em;
  padding: 1em;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: ${(props) => props.theme.borderRadius};
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  margin-top: 0.5em;
`;

export const SAdminPendingTransaction = styled(SProcessingTransaction)`
  margin-top: 1em;
`;

export const SAdminPendingTransactionLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.green};
  padding: 5px 10px;
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.main};
  font-weight: 700;
`;
