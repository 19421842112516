// styled-components
import styled from "styled-components";

export const SSignUp = styled.div`
  padding: 1em 1em 4em 1em;
  background-color: ${(props) => props.theme.colors.white};
  height: fit-content;
`;

export const SForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const SAgreement = styled.div`
  display: flex;
  gap: 1em;

  & > input {
    width: 1.5em;
    height: 1.5em;
  }
`;

export const SSubmit = styled.input`
  margin-bottom: 5em;
  height: 3.5em;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.main};
  background-color: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.white};
  font-size: 1em;
`;
