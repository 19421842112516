import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../context";
import { currencyDisplayer } from "../../utils";
import { TTransaction } from "../Transactions/types";
// components
import PageTitle from "../utils/PageTitle";
import StepUnit from "./StepUnit";
import WithdrawalRequest from "./WithdrawalRequest";
// styles
import {
  STransaction,
  SOverview,
  SOverviewHeader,
  STransactionCodeTitle,
  STransactionCodeValue,
  STransactionState,
  SAmountTitle,
  SAmountValue,
  SSectionSender,
  SSectionSenderContainer,
  SFrom,
  SHistory,
  SHistoryTitle,
  SRequestWidrawal,
  SEndTransaction,
  STransactionMessageTitle,
  STransactionMessage,
} from "./styles";
// react-icons
import { FiDownload } from "react-icons/fi";
import GetUser from "../utils/GetUser";
// types
import { WithdrawalRequestData } from "./types";

const Transaction: React.FC = (): JSX.Element => {
  const [requestingWithdrawal, setRequestingWithdrawal] = React.useState(false);
  const [withdrawalRequestPlaced, setWithdrawalRequestPlaced] =
    React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const transaction: TTransaction = location.state as TTransaction;

  const amount = currencyDisplayer(
    transaction.amount! as number,
    transaction.currency as string,
  );

  const { user } = React.useContext(Context);
  const isSender = transaction.senderEmail === user?.email;

  // This is the data that should be fetched from the
  // transaction itself
  const withdrawalData: WithdrawalRequestData = {
    amount: transaction.amount,
    currency: transaction.currency,

    // TODO
    // 1- Get the current selling rate from the API
    // 2- If the user places the withdrawal, update the transaction
    //    object with the current selling rate.
    sellingRate: transaction.sellingRate,
    
    transactionCode: transaction.code,
  };

  return (
    <STransaction>
      <GetUser />
      <PageTitle text="Transaction details" />
      <SOverview>
        <SOverviewHeader>
          <div>
            <STransactionCodeTitle>Transaction code</STransactionCodeTitle>
            <STransactionCodeValue>{transaction.code}</STransactionCodeValue>
          </div>
          <STransactionState>
            <p>
              {transaction.state === "received" ? "completed" : "processing"}
            </p>
          </STransactionState>
        </SOverviewHeader>
        <SAmountTitle>Amount</SAmountTitle>
        <SAmountValue>{amount}</SAmountValue>
        <STransactionMessageTitle>Message</STransactionMessageTitle>
        <STransactionMessage>{transaction.message || "-"}</STransactionMessage>
        <SSectionSenderContainer>
          <SFrom>{isSender ? "to" : "from"}</SFrom>
          <SSectionSender>
            {isSender ? transaction.receiverEmail : transaction.senderFullName}
          </SSectionSender>
        </SSectionSenderContainer>
      </SOverview>
      <SHistory>
        <SHistoryTitle>States</SHistoryTitle>
        <StepUnit
          item={{
            type: "Transaction successfully initialized",
            time: transaction?.history?.states[0]?.serverTime,
          }}
        />
        {transaction?.history?.states[1] && (
          <StepUnit
            item={{
              time: transaction?.history?.states[1]?.serverTime,
              type: "Transaction ready for withdrawal",
            }}
          />
        )}
        {transaction?.history?.states[2] && (
          <StepUnit
            item={{
              time: transaction?.history?.states[2]?.serverTime,
              type: "Withdrawal request placed",
            }}
          />
        )}
        {transaction?.history?.states[3] && (
          <StepUnit
            item={{
              time: transaction?.history?.states[3]?.serverTime,
              type: "Transaction completed",
            }}
          />
        )}
      </SHistory>
      {transaction?.state === "available" &&
        !requestingWithdrawal &&
        !withdrawalRequestPlaced &&
        !isSender && (
          <SRequestWidrawal onClick={() => setRequestingWithdrawal(true)}>
            <FiDownload />
            <p>Request withdrawal</p>
          </SRequestWidrawal>
        )}
      {requestingWithdrawal && (
        <WithdrawalRequest
          withdrawalData={withdrawalData}
          setRequestingWithdrawal={setRequestingWithdrawal}
          setWithdrawalRequestPlaced={setWithdrawalRequestPlaced}
        />
      )}
      {transaction?.state === "received" && (
        <SEndTransaction
          onClick={() => navigate("/transactions", { replace: true })}
        >
          <p>Go back</p>
        </SEndTransaction>
      )}
    </STransaction>
  );
};

export default Transaction;
