import React from "react";
// utils
import { fetchTransactions } from "./utils";
// components
import GetUser from "../utils/GetUser";
import PageTitle from "../utils/PageTitle";
import Section from "./Section";
// styles
import {
  STransaction,
  SOptionsContainer,
  SCategory,
  SCategoryContainer,
  SCategoryCount,
} from "./styles";
// types
import { TTransaction } from "./types";

const AdminTransactions: React.FC = (): JSX.Element => {
  const categories = [
    "All",
    "Sent",
    "Available",
    "Requested",
    "Handling",
    "Received",
  ];

  const initialTransactionValue: TTransaction[] = [];

  const [transactions, setTransactions] = React.useState(
    initialTransactionValue,
  );
  const [currentCategory, setCurrentCategory] = React.useState("All");

  React.useEffect(() => {
    (async function () {
      const response = await fetchTransactions();
      if (response) setTransactions(response);
    })();
  }, []);

  const currentCategoryCount = () => {
    if (currentCategory === "All") return transactions.length;
    return transactions.filter(
      (item) => item.state?.toLowerCase() === currentCategory.toLowerCase(),
    ).length;
  };

  return (
    <STransaction>
      <GetUser />
      <PageTitle text="Transactions" />
      <SOptionsContainer>
        {categories.map((item, index) => {
          return (
            <SCategoryContainer key={index}>
              <SCategory
                optional={{ isSelected: currentCategory === item }}
                onClick={() => setCurrentCategory(item)}
              >
                {item}
              </SCategory>
              {(currentCategory === item) && (
                <SCategoryCount>{currentCategoryCount()}</SCategoryCount>
              )}
            </SCategoryContainer>
          );
        })}
      </SOptionsContainer>
      <Section
        title=""
        elements={transactions.filter((item) => {
          if (currentCategory.toLocaleLowerCase() === "all") return item;
          return item.state === currentCategory.toLocaleLowerCase();
        })}
      />
    </STransaction>
  );
};

export default AdminTransactions;
