import React from "react";
// react-icons
import { HiMenuAlt3, HiOutlineX } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import { theme } from "../../../styles/theme";
// data
import {
  logged_out_menu,
  logged_in_menu,
  entry_text,
} from "../../../data/texts/entry";
// react-router
import { Link, useLocation, useNavigate } from "react-router-dom";
// types
import { MenuOptionProps, MenuProps } from "./types";
// styles
import {
  SAdminLabel,
  SAdminLabelContainer,
  SHeader,
  SLabelOption,
  SMenu,
  SMenuOption,
  SMenuOptions,
  SMission,
  STextOption,
  STitle,
  STitleContainer,
  STitleOption,
} from "./styles";
// components
import GetUser from "../GetUser";
import { Context } from "../../../context";

const Header: React.FC = (): JSX.Element => {
  const [isMenuDisplayed, DisplayMenu] = React.useState(false);

  const location = useLocation();
  const isViewBlueHeader =
    location.pathname === "/" || location.pathname === "/start";

  const navigate = useNavigate();

  const { user, isAdminMode, setAdminMode } = React.useContext(Context);

  const direction = Object.keys(user).length > 0 ? "/start" : "/";

  const menu = Object.keys(user).length > 0 ? logged_in_menu : logged_out_menu;

  const isAdmin = user?.isAdmin;

  const handleAdminButtonClick = () => {
    setAdminMode(!isAdminMode);
  };

  return (
    <SHeader
      optional={{
        isMenu: isMenuDisplayed || !isViewBlueHeader,
      }}
    >
      <GetUser />
      <STitleContainer>
        <STitle
          onClick={() => navigate(direction, { replace: true })}
          optional={{
            isMenu: isMenuDisplayed || !isViewBlueHeader,
          }}
        >
          Konekte
        </STitle>
        {isAdmin && (
          <SAdminLabelContainer onClick={handleAdminButtonClick}>
            <SAdminLabel>Admin</SAdminLabel>
          </SAdminLabelContainer>
        )}
        {isAdminMode && (
          <FiCheck
            size={20}
            color={isViewBlueHeader ? theme.colors.white : theme.colors.main}
          />
        )}
      </STitleContainer>
      {isMenuDisplayed ? (
        <HiOutlineX
          onClick={() => DisplayMenu(!isMenuDisplayed)}
          color={theme.colors.main}
          size="1.6em"
        />
      ) : (
        <HiMenuAlt3
          onClick={() => DisplayMenu(!isMenuDisplayed)}
          color={isViewBlueHeader ? theme.colors.white : theme.colors.main}
          size="1.6em"
        />
      )}
      {isMenuDisplayed && (
        <Menu
          optional={{
            isMenu: isMenuDisplayed || !isViewBlueHeader,
          }}
          isMenuDisplayed={isMenuDisplayed}
          DisplayMenu={DisplayMenu}
          menu={menu}
        />
      )}
    </SHeader>
  );
};

const Menu: React.FC<MenuProps> = ({
  isMenuDisplayed,
  DisplayMenu,
  menu,
}): JSX.Element => {
  return (
    <SMenu>
      <SMenuOptions>
        {menu.map((item, index) => {
          return (
            <Link
              to={item.path}
              style={{
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => DisplayMenu(!isMenuDisplayed)}
              key={index}
            >
              <MenuOption
                title={item.title}
                text={item.text}
                label={item.label}
                key={index}
              />
            </Link>
          );
        })}
      </SMenuOptions>
      <SMission>
        <p>{entry_text.mission}</p>
        <p onClick={() => console.log("Read more")}>Read more</p>
      </SMission>
    </SMenu>
  );
};

const MenuOption: React.FC<MenuOptionProps> = ({
  title,
  text,
  label,
}): JSX.Element => {
  return (
    <SMenuOption>
      {title && <STitleOption>{title}</STitleOption>}
      {text && <STextOption>{text}</STextOption>}
      {label && (
        <SLabelOption>
          <p>{label}</p>
        </SLabelOption>
      )}
    </SMenuOption>
  );
};

export default Header;
