import React from "react";

const SVG : React.FC = () : JSX.Element => {
  return (
    <svg width="280" height="170" viewBox="0 0 294 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 173.694C44.341 157.769 82.3186 134.851 117.177 105.829C137.806 88.6538 169.997 59.8315 168.238 29.0438C167.43 14.9083 153.872 7.35198 140.704 10.4292C129.327 13.088 119.848 27.0379 114.334 36.2828C110.351 42.96 105.969 52.0767 108.517 60.068C112.226 71.7034 128.256 76.8515 138.765 78.6825C199.332 89.2359 251.753 46.1222 290.525 5" stroke="white" strokeWidth="3" strokeLinecap="round"/>
      <path d="M269.546 7.22759C272.596 7.22759 291.651 1.60651 291.651 2.57395C291.651 7.08459 289.324 11.3093 289.324 15.8885C289.324 18.8186 289.324 21.7486 289.324 24.6787" stroke="white" strokeWidth="3" strokeLinecap="round"/>
    </svg>
  )
}

export default SVG;