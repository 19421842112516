import React, { Dispatch, SetStateAction } from "react";

export type User = {
  currentWeekTxTotal?: number;
  email?: string;
  firstname?: string;
  lastname?: string;
  handledTransaction?: string[];
  isAdmin?: boolean;
  isReceived?: boolean;
  isSender?: boolean;
  isVerifiedUser?: boolean;
  points?: number;
  receivedTransactions?: string[];
  sentTransactions?: string[];
  txLimitPerWeek?: number;
  _id?: string;
};

const initialValue: User = {};


type UserContext = {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  isAdminMode: boolean;
  setAdminMode: Dispatch<SetStateAction<boolean>>
};

export const Context = React.createContext<UserContext>({
  user: initialValue,
  setUser: () => {},
  isAdminMode: false,
  setAdminMode: () => {}
});

type Props = {
  children: JSX.Element;
};

const ContextProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [user, setUser] = React.useState(initialValue);
  const [isAdminMode, setAdminMode] = React.useState(false);

  const data = {
    user,
    setUser,
    isAdminMode,
    setAdminMode
  };

  // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //   // const url = process.env.REACT_APP_BASE_URL + "/";

  //   // const fetchedUser = axios.post()
  // }

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export default ContextProvider;
