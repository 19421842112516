import styled from "styled-components";

export const SSectionUnit = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  padding: 1em;
  border-radius: ${(props) => props.theme.borderRadius};
  margin-bottom: 1em;
`;

export const SSectionUnitHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SSectionUnitCode = styled.p`
  font-size: 0.9em;
  font-weight: 600;
  color: ${(props) => props.theme.colors.dark};
`;

export const SSectionUnitStatusContainer = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  padding: 5px 10px;
  border-radius: 15px;

  & > p {
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const SSectionAmount = styled.p`
  color: ${(props) => props.theme.colors.main};
  font-weight: 600;
  font-size: 1.3em;
`;

export const SSectionSenderContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 0.5em;
`;

export const SFrom = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-size: 0.9em;
`;

export const SSectionSender = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  font-size: 0.9em;
`;

export const STransaction = styled.div`
  padding: 1em;
`;
type OptionProps = {
  optional?: {
    isSent: boolean;
  };
};

type CategoryProps = {
  optional?: {
    isSelected: boolean;
  };
};

export const SSent = styled.p<OptionProps>`
  font-weight: ${(props) => (props.optional?.isSent ? "700" : "400")};
`;

export const SReceived = styled.p<OptionProps>`
  font-weight: ${(props) => (props.optional?.isSent ? "400" : "700")};
`;

export const SCategoryContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const SCategory = styled.p<CategoryProps>`
  font-weight: ${(props) => (props.optional?.isSelected ? "700" : "400")};
`;

export const SCategoryCount = styled.div`
  border-radius: 50vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.main};
  font-weight: 700;
  min-width: 2.5em;
`;

export const SOptionsContainer = styled.div`
  display: flex;
  gap: 1.5em;
  overflow-x: scroll;
  width: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }
`;

export const SSectionTransactions = styled.div`
  padding-top: 1em;
`;

export const SSectionTransactionsTitle = styled.p`
  margin-bottom: 1em;
  font-size: 1.1em;
  font-weight: 500;
`;

export const SSelectedPart = styled.div``;

export const SStateTextContainer = styled.div`
  padding: 1em 0 0 0.5em;
  display: flex;
  gap: 0.4em;
  margin-bottom: 0.5em;
`;

export const SStateText = styled.div`
  font-size: 0.9em;
  color: ${(props) => props.theme.colors.dark};
`;

export const SDetailsContainer = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.gray};
  margin: 1.5em 0 0 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
`;

export const SDetails = styled.div`
  display: flex;
  gap: 0.5em;
`;

export const SBold = styled.div`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  font-size: 0.9em;
`;

export const SNormalText = styled.div`
  color: ${(props) => props.theme.colors.dark};
  font-size: 0.9em;
`;

type SActionButtonProps = {
  optional?: {
    bg?: string;
  };
};

export const SActionButtonContainer = styled.div`
  display: flex; 
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2em;
`

export const SActionButton = styled.div<SActionButtonProps>`
  background-color: ${(props) =>
    props.optional?.bg ? props.optional?.bg : props.theme.colors.main};
  padding: 8px 15px;
  width: fit-content;
  border-radius: 100px; // ${props => props.theme.borderRadius};
  align-self: flex-end;
  margin-top: 1em;
  
  & > p {
    color: ${props => props.theme.colors.white};
    font-weight: 300;
  }
`;

export const SWaitingForWithdrawalMessage = styled.p`
  color: rgba(39, 39, 39, 0.8);
  font-weight: 500;
  margin-top: 2em;
`;

export const SConfirmText = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 0.85em;
  padding-top: 1em;
  line-height: 1.3em;
  margin-left: 1em;
  font-weight: 700;
`;

export const SReceiptInput = styled.input`
  
`;