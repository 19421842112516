import React from "react";
// data
import { info_text } from "../../data/texts/entry";
// styled-components
import styled from "styled-components";

// types
type UnitProps = {
  title: string;
  text: string;
}

const Infos : React.FC = () : JSX.Element => {
  return (
   <>
      {
        info_text.map((item, index) => {
          return (
            <SInfos key={index} >
              <Section title={item.title} text={item.text} />
              {/* <SButton>
                <StartNowText>Start Now</StartNowText>
              </SButton> */}
            </SInfos>
          ) 
        })
      }
   </>
  )
}

const Section : React.FC<UnitProps> = ({title, text}) : JSX.Element => {
  return (
    <SSection>
      <SSectionTitle>{title}</SSectionTitle>
      <SSectionText>{text}</SSectionText>
    </SSection>
  )
}

const SInfos = styled.div`
  display: flex;
  flex-direction: column;
`

const SSection = styled.div`
  padding-top: 3em;
  padding-left: 1em;
  padding-right: 1em;
`;

const SSectionTitle = styled.p`
  font-size: 1.6em;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 1em;
`;

const SSectionText = styled.p`
  color: ${props => props.theme.colors.dark};
  line-height: 1.8em;
  font-size: 1.1em;
  font-weight: 400;
`;

export default Infos;