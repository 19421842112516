import React from "react";
import { useNavigate } from "react-router-dom";
// types
import { SectionUnitProps} from "./types"
// styles
import {
  SSectionUnit,
  SSectionUnitCode,
  SSectionUnitHeader,
  SSectionUnitStatusContainer,
  SSectionAmount,
  SSectionSenderContainer,
  SSectionSender,
  SFrom
} from "./styles"
// utils
import { currencyDisplayer } from "../../utils";


const SectionUnit: React.FC<SectionUnitProps> = (props): JSX.Element => {
  
  const amount = currencyDisplayer(
    props.element.amount!,
    props.element.currency,
  );

  const navigate = useNavigate();

  return (
    <SSectionUnit onClick={() => navigate("/transaction", {state: props.element})}>
      <SSectionUnitHeader>
        <SSectionUnitCode>{props.element.code}</SSectionUnitCode>
        <SSectionUnitStatusContainer>
          <p>
            {props.element.state === "received" ? "completed" : "processing"}
          </p>
        </SSectionUnitStatusContainer>
      </SSectionUnitHeader>
      <SSectionAmount>{amount}</SSectionAmount>
      <SSectionSenderContainer>
        <SFrom>from</SFrom>
        <SSectionSender>{props.element.senderFullName}</SSectionSender>
      </SSectionSenderContainer>
    </SSectionUnit>
  );
};

export default SectionUnit;