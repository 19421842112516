import React from "react";
// styled-components
import styled from "styled-components";
// components
import InputUnit, { InitialValueFormAuth } from "../utils/InputField";
import PageTitle from "../utils/PageTitle";
import Button from "../utils/Button";
import GetUser from "../utils/GetUser";
import ErrorBox from "../utils/ErrorBox";
// data
import { units_log_in } from "../../data/texts/entry";
// react-icons
import { HiExternalLink } from "react-icons/hi";
import axios from "axios";
import { Context } from "../../context";
import { Link, useNavigate } from "react-router-dom";
// utils
import { meetConditionLogin } from "../../utils";
import { email_password_incorrect } from "../../data/texts/entry";


const Login: React.FC = (): JSX.Element => {

  const initialValues : InitialValueFormAuth  = {
    email: "", 
    password: ""
  }
  const [data, setData] = React.useState(initialValues);
  // type response = "inactive" | "resolved" | "pending" | "rejected"
  const [res, setRes] = React.useState("inactive");

  const { user, setUser } = React.useContext(Context);
  
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Object.keys(user).length !== 0)
      navigate("/start", { replace: true });
  }, [navigate, user]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const cond = meetConditionLogin(data.email, data.password);
    if (!cond) console.log("incomplete data");
    else {
      const url = process.env.REACT_APP_BASE_URL + "/auth/login";
      try {
        setRes("pending");
        const fetchedUser = await axios.post(url, data);
        setRes("resolved");
        // save the fetchedUser in the LocalStorage
        localStorage.setItem(
          "user",
          JSON.stringify(fetchedUser.data.data.accessToken),
        );
        // save the fetchedUser as state in the app
        setUser(fetchedUser.data.data.user);
        // redirect to /start
        navigate("/start", { replace: true });
      } catch (error: any) {
        console.error(error);
        setData({ email: "", password: "" });
        setRes("rejected");
      }
    }
  };

  return (
    <SLogin>
      <GetUser />
      <PageTitle text="Login" />
      {res === "rejected" && (
        <ErrorBox txt={email_password_incorrect} />
      )}
      <SForm onSubmit={handleSubmit}>
        {units_log_in.map((item, index) => {
          return (
            <InputUnit
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              subtitle={item.subtitle}
              state={data}
              stateSetter={setData}
              type={item.type}
              id={item.id}
              error_message={item.error_message}
              pattern={item.pattern}
            />
          );
        })}
        <Button
          agreement_text="By clicking, you accept the conditions and our user agreement."
          input_id="start-checkout"
          input_name="start-checkout"
          input_type="checkbox"
          input_value="1"
          submit_value="Login"
          link_icon={<HiExternalLink size="2em" />}
          condition_met={() => meetConditionLogin(data.email, data.password)}
          callback={res}
        />
      </SForm>
      <p>You don't have an account? <Link to={"../sign-up"}>Sign Up</Link></p>
    </SLogin>
  );
};

const SLogin = styled.div`
  padding: 1em 1em 4em 1em;
  background-color: ${(props) => props.theme.colors.white};
  height: fit-content;
`;

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export default Login;
