import React from "react";
// react-router
import { Routes, Route } from "react-router-dom";
// layouts
import Layout from "./views/utils/Layout";
// views
import Entry from "./views/Entry";
import SignUp from "./views/SignUp";
import Login from "./views/Login";
import Track from "./views/Track";
import Start from "./views/Start";
import Sending from "./views/Sending";
import SendVerdict from "./views/SendVerdict";
import Transactions from "./views/Transactions";
import Transaction from "./views/Transaction";
import Checkout from "./views/Checkout";
// context
import ContextProvider from "./context";

const App: React.FC = (): JSX.Element => {   

  return (
    <ContextProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Entry />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/track" element={<Track />} />
          <Route path="/start" element={<Start />} />
          <Route path="/sending" element={<Sending />} />
          <Route path="/send-verdict" element={<SendVerdict />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </Layout>
    </ContextProvider>
  );
};

export default App;
