import React from "react";
import axios from "axios";
// data
import { signup_error, units_sign_up } from "../../data/texts/entry";
// react-icons
import { HiExternalLink } from "react-icons/hi";
// components
import InputUnit, {
  InitialValueFormAuth,
} from "../utils/InputField";
import PageTitle from "../utils/PageTitle";
import Button from "../utils/Button";
import ErrorBox from "../utils/ErrorBox";
// styles
import { SForm, SSignUp } from "./styles";
// utils
import { meetConditionSignUp } from "../../utils";
import { Context } from "../../context";
import { useNavigate, Link } from "react-router-dom";
import GetUser from "../utils/GetUser";

const SignUp: React.FC = (): JSX.Element => {
  const initialValues: InitialValueFormAuth = {
    firstname: "",
    lastname: "",
    email: "",
    password1: "",
    password2: "",
  };

  const [data, setData] = React.useState(initialValues);
  // type response = "inactive" | "pending" | "resolved" | "rejected"
  const [res, setRes] = React.useState("inactive");

  const { user, setUser } = React.useContext(Context);
  
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Object.keys(user).length !== 0)
      navigate("/start", { replace: true });
  }, [navigate, user]);

  const handleSubmit = async (
    event: React.ChangeEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    // Check if conditions are met
    const cond = meetConditionSignUp(data);
    if (!cond) {
      console.log("All conditions are not met")
    } else {
      // Make api call
      const payload = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password1,
      };
      const url =
        process.env.REACT_APP_BASE_URL +
        "/auth/signup";
      try {
        setRes("pending");
        const fetchedUser = await axios.post(url, payload);
        setRes("resolved");
        // save the fetchedUser in the LocalStorage
        localStorage.setItem(
          "user",
          JSON.stringify(fetchedUser.data.data.accessToken),
        );
        // save the fetchedUser as state in the app
        setUser(fetchedUser.data.data.user);
        // redirect to /start
        navigate("/start", { replace: true });
      } catch (error) {
        console.error(error);
        setRes("rejected");
        setData(initialValues);
      }
    }
  };

  return (
    <SSignUp>
      <GetUser />
      <PageTitle text="Sign Up" />
      {res === "rejected" && (
        <ErrorBox txt={signup_error} />
      )}
      <SForm onSubmit={handleSubmit}>
        {units_sign_up.map((item, index) => {
          return (
            <InputUnit
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              placeholder={item.placeholder}
              type={item.type}
              state={data}
              stateSetter={setData}
              id={item.id}
              pattern={item.pattern}
              error_message={item.error_message}
            />
          );
        })}
        <Button
          agreement_text="By clicking, you accept the conditions and our user agreement."
          input_id="start-checkout"
          input_name="start-checkout"
          input_type="checkbox"
          input_value="1"
          submit_value="Sign Up"
          link_icon={<HiExternalLink size="2em" />}
          condition_met={() => meetConditionSignUp(data)}
          callback={res}
        />
      </SForm>
      <p>You already have an account? <Link to={"../login"}>Log in</Link></p>
    </SSignUp>
  );
};

export default SignUp;
