export type Amounts = {
  amountReceived: number;
  amountSpent: number;
  transferFee: number;
  cardFee: number;
  totalCost: number;
};

const TRANSFER_FEE_PERCENT = 0.0299;
const SQUARE_CARD_FEE_PERCENT = 0.029;
const SQUARD_CONSTANT_FEE = 0.3;

export const defineAmounts = (amount?: number): Amounts => {
  if (amount) {
    console.log(amount);

    const amountReceived: number = amount;
    const amountSpent: number = amount * (1 + TRANSFER_FEE_PERCENT);
    const transferFee: number = amount * TRANSFER_FEE_PERCENT;
    const totalCost: number =
      (amountSpent + SQUARD_CONSTANT_FEE) / (1 - SQUARE_CARD_FEE_PERCENT);
    const cardFee: number = totalCost - amountSpent;

    const amounts: Amounts = {
      amountReceived: Math.round(amountReceived * 100) / 100,
      amountSpent: Math.round(amountSpent * 100) / 100,
      transferFee: Math.round(transferFee * 100) / 100,
      cardFee: Math.round(cardFee * 100) / 100,
      totalCost: Math.round(totalCost * 100) / 100,
    };
    return amounts;
  }

  return {
    amountReceived: NaN,
    amountSpent: NaN,
    transferFee: NaN,
    cardFee: NaN,
    totalCost: NaN,
  };
};
