import React from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// styles
import {
  SCheckout,
  SField,
  SFieldElement,
  SFieldElementBold,
  SFieldElementNormal,
  SFieldTitle,
} from "./styles";
// types
import { CheckoutProps, TLocation } from "./types";
import { Context } from "../../context";
import PageTitle from "../utils/PageTitle";
import { defineAmounts } from "../../utils/defineAmounts";
import { currencyDisplayer } from "../../utils";
// components
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";

const Checkout: React.FC = (): JSX.Element => {
  const { user } = React.useContext(Context);

  const navigate = useNavigate();
  const location = useLocation() as TLocation;
  const checkoutProps: CheckoutProps = location.state;

  let accessToken = localStorage.getItem("user");
  accessToken = JSON.parse(accessToken!);

  const amounts = defineAmounts(checkoutProps.data.amount);
  console.log(amounts)
  
  // @ts-ignore
  const handleClick = async (token, buyer) => {
    if (token.status !== "OK") {
      alert("Something wrong happens with your payment!");
    } else {
      const payload = {
        senderID: user._id,
        senderEmail: user.email,
        senderFullName: user.firstname + " " + user.lastname,
        receiverEmail: checkoutProps.data.email,
        amount: checkoutProps.data.amount,
        currency: "USD",
        time: Date.now(),
        message: checkoutProps.data.message,
        squareToken: token.token,
        squareAmount: amounts.totalCost * 100,  // Square takes everything as cents for usd
      };

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const url = process.env.REACT_APP_BASE_URL + "/transaction";

      try {
        const response = await axios.post(url, payload, config);
        navigate("/send-verdict", {
          replace: true,
          state: response.data.data,
        });
      } catch (error) {
        navigate("/send-verdict");
      }
    }
  };


  const receiverInformation = [
    { title: "Email", value: checkoutProps.data.email },
  ];

  const amountInformation = [
    {
      title: "Amount to be received",
      value: currencyDisplayer(amounts.amountReceived, "USD"),
    },
    {
      title: "Amount spent",
      value: currencyDisplayer(amounts.amountSpent, "USD"),
    },
    {
      title: "Transfer fee (2.99%)",
      value: currencyDisplayer(amounts.transferFee, "USD"),
    },
    {
      title: "Card fee (2.90% + $0.3)",
      value: currencyDisplayer(amounts.cardFee, "USD"),
    },
    {
      title: "Total cost",
      value: currencyDisplayer(amounts.totalCost, "USD"),
    },
  ];

  const appID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
  const locID = process.env.REACT_APP_SQUARE_LOCATION_ID;

  return (
    <SCheckout>
      <PageTitle text="Checkout" />
      <Field items={receiverInformation} />
      <Field items={amountInformation} />
      <PaymentForm
        applicationId={appID!}
        locationId={locID!}
        cardTokenizeResponseReceived={(token, buyer) =>
          handleClick(token, buyer)
        }
      >
        <CreditCard>
          <p>{`Pay ${currencyDisplayer(amounts.totalCost, "USD")}`}</p>
        </CreditCard>
      </PaymentForm>
    </SCheckout>
  );
};

type FieldProps = {
  items: { title: string; value?: string | number }[];
};

const Field: React.FC<FieldProps> = (props): JSX.Element => {
  return (
    <SField>
      <SFieldTitle>Receiver information</SFieldTitle>
      {props.items.map((item, index) => {
        return (
          <SFieldElement key={index}>
            <SFieldElementBold>{item.title}</SFieldElementBold>
            <SFieldElementNormal>{item.value}</SFieldElementNormal>
          </SFieldElement>
        );
      })}
    </SField>
  );
};

export default Checkout;
