import React from "react";
// styled-components
import styled from "styled-components";

type ButtonProps = {
  input_type?: string;
  input_id?: string;
  input_name?: string;
  input_value?: string;
  agreement_text?: string;
  link_icon?: JSX.Element;
  submit_value: string;
  condition_met?: () => boolean | undefined;
  callback?: string;
};

const Button: React.FC<ButtonProps> = (props): JSX.Element => {
  const [enabled, setEnabled] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    if (!props.agreement_text) setEnabled(true);
  }, [props]);

  const handleSubmitClick = () => {
    if (props.condition_met && props.condition_met()) {
      setClicked(true);
    }
  };

  return (
    <SButton>
      <SAgreement>
        {props.input_type && (
          <SInput
            type={props.input_type}
            id={props.input_id}
            name={props.input_name}
            value={props.input_value}
            onClick={() => setEnabled(!enabled)}
          />
        )}
        {props.agreement_text && <p>{props.agreement_text}</p>}
        {props.link_icon}
      </SAgreement>
      <SSubmit
        type="submit"
        value={
          clicked && props.callback === "pending"
            ? "Loading"
            : props.submit_value
        }
        disabled={!enabled}
        onClick={handleSubmitClick}
        optional={{ clicked: clicked, callback: props.callback }}
      />
    </SButton>
  );
};

const SButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SAgreement = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5em;
`;

const SInput = styled.input`
  width: 2em;
  height: 2em;
`;

export type StyleProps = {
  optional?: {
    clicked?: boolean;
    callback?: string;
  };
};

const SSubmit = styled.input<StyleProps>`
  margin-bottom: 2em;
  height: 3.5em;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid
    ${(props) =>
      props.optional?.clicked && props.optional.callback === "pending"
        ? props.theme.colors.gray
        : props.theme.colors.main};
  background-color: ${(props) =>
    props.optional?.clicked && props.optional.callback === "pending"
      ? props.theme.colors.gray
      : props.theme.colors.main};
  color: ${(props) =>
    props.optional?.clicked && props.optional.callback === "pending"
      ? props.theme.colors.main
      : props.theme.colors.white};
  font-size: 1em;
  width: 100%;
`;

export default Button;
