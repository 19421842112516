import React from "react";
// styled-components
import styled from "styled-components";
// react-router-dom
import { useNavigate } from "react-router-dom";
// components
import InputUnit from "../utils/InputField";
import PageTitle from "../utils/PageTitle";
import axios from "axios";
import { TTransaction } from "../Transactions/types";

export type InitialValueTrack = {
  value?: string;
};

const Track: React.FC = (): JSX.Element => {
  const initVal: InitialValueTrack = {
    value: "",
  };

  const [code, setCode] = React.useState(initVal);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response: TTransaction = (await getTransaction(
      code.value,
    )) as unknown as TTransaction;
    navigate("../transaction", { replace: true, state: response! });
  };

  const getTransaction = async (code?: string) => {
    const url =
      process.env.REACT_APP_BASE_URL + `/transaction?code=${code}`;
    const token = JSON.parse(localStorage.getItem("user")!);
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(url, options);
    return res.data.data[0];
  };

  return (
    <STrack>
      <PageTitle text="Transaction tracking" />
      <SForm onSubmit={handleSubmit}>
        <InputUnit
          title="Transaction code"
          placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _"
          subtitle="Please, enter a valid transaction code"
          required={true}
          state={code}
          stateSetter={setCode}
          id="value"
          type="text"
          pattern={/\w/}
        />
        <SSubmit type="submit" value="Track" />
      </SForm>
    </STrack>
  );
};

const STrack = styled.div`
  padding: 1em;
`;

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const SSubmit = styled.input`
  margin-bottom: 5em;
  height: 3.5em;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.main};
  background-color: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.white};
  font-size: 1em;
  width: fit-content;
  padding: 0 3em;
  align-self: flex-end;
`;

export default Track;
