import React from "react";
// styled-components
import styled from "styled-components";
// data
import { entry_text, contact, address } from "../../data/texts/entry";
// react-icons
import {HiExternalLink} from "react-icons/hi";
import {FiMail, FiInstagram, FiTwitter} from "react-icons/fi"

const Footer : React.FC = () : JSX.Element => {
  return (
    <SFooter>
      <>
        <STitle>Konekte</STitle>
        <SMission>{entry_text.mission} <HiExternalLink size="1.4em"/></SMission>
      </>
      <Address />
      <Company />
    </SFooter>
  )
}

const Address : React.FC = () : JSX.Element => {
  return (
    <SAddress>
      <SLink><FiMail size="1.5em" color="white"/> <p>{contact.mail}</p></SLink>
      <SLink><FiTwitter size="1.5em" color="white"/> <p>{contact.twitter}</p></SLink>
      <SLink><FiInstagram size="1.5em" color="white"/> <p>{contact.instagram}</p></SLink>
    </SAddress>
  )
}

const Company : React.FC = () : JSX.Element => {
  return (
    <SCompany>
      <SLLC>Konekte LLC</SLLC>
      <SAddressElement>{address.street}</SAddressElement>
      <SAddressElement>{address.city}</SAddressElement>
      <SAddressElement>{address.country}</SAddressElement>
      <SCopyright>Copyright © 2022 Konekte LLC.</SCopyright>
    </SCompany>
  )
}

const SFooter = styled.div`
  height: fit-content;
  background-color: ${props => props.theme.colors.main};
  margin-top: 10em;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const STitle = styled.p`
  font-family: "Montserrat Alternates";
  color: ${props => props.theme.colors.white};
  font-size: 1.3em;
`;

const SMission = styled.p`
  color: #c4c4c4;
  margin-top: 2em;
  line-height: 2em;
  font-size: 1em;
`;

const SLink = styled.div`
  gap: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 0;

  & > p {
    font-size: 1em;
    color: #c4c4c4
  }
`;

const SAddress = styled.div`
  padding: 2em 0;
`;

const SCompany = styled.div`

`;

const SAddressElement = styled.p`
  color: ${props => props.theme.colors.white};
  line-height: 2em;
`;

const SLLC = styled.p`
  color: ${props => props.theme.colors.white};
  line-height: 2em;
  font-weight: 500;
  margin-bottom: 0.5em;
  font-size: 1.2em;
`

const SCopyright = styled.p`
  color: ${props => props.theme.colors.white};
  line-height: 2em;
  font-weight: 300;
`

export default Footer;