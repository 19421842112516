import { To } from "react-router-dom";

export const entry_text = {
  overview:
    "Simplifying value transaction with people and technologies.",
  warning:
    "By clicking the above button, you are agreeing with our User Agreement.",
  mission:
    "Our mission is to create a worldwide decentralised network of people and technologies that allow a wide variety of convertible value transaction.",
};

export const tracking_text = {
  informational:
    "This window allows the tracking of a transaction emited under the following transaction number.",
};

export const contact = {
  instagram: "@konekte",
  twitter: "@konekte",
  mail: "contact@konekte.co",
};

export const address = {
  street: "317 SouthRidge Road",
  city: "Delray Beach, FL",
  country: "US 33444",
};

type UnitProps = {
  title: string;
  text: string;
};

export const info_text: UnitProps[] = [
  {
    title: "More than money transfer",
    text: "Transfering money is a big matter to deal with. We provide the first geographically unlimited platform that allow the exchange convertible values by leveraging standard financial facilities, cryptocurrencies and peer-to-peer networks.",
  },
  {
    title: "Merging technologies and people",
    text: "Our system uses cutting-edge technologies coupled with people to form a unbreakable network to allow the transaction of convertible values accross all humankind.",
  },
];

export type LoggedMenu = {
  title?: string;
  text?: string;
  path: To;
  label?: string;
};

export const logged_out_menu: LoggedMenu[] = [
  {
    title: "Sign up",
    // text: "And get a free transaction",
    path: "sign-up",
  },
  { title: "Login", path: "login" },
  { title: "About us", path: "about" },
  { title: "User Agreement", path: "agreement" },
  // { title: "Invite", path: "invite" },
];

export const logged_in_menu: LoggedMenu[] = [
  { title: "Transactions", path: "transactions" },
  { title: "About us", path: "about" },
  { title: "User Agreement", path: "agreement" },
  { title: "Invite", path: "invite" },
];

type InputUnitProps = {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  type: string;
  id?: string;
  pattern?: RegExp;
  error_message?: string;
  validator_func?: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
};

export const units_sign_up: InputUnitProps[] = [
  {
    id: "firstname",
    title: "First name",
    placeholder: "John",
    type: "text",
    pattern: /^[\w](?:[a-zA-Z \- ]*[a-zA-Z])$/,
    error_message:
      "The first name should at least be 2 characters (!@#$%^&*-), alphabetic and not end with non alphabetic characters.",
  },
  {
    id: "lastname",
    title: "Last name",
    placeholder: "McCarter",
    type: "text",
    pattern: /^[\w][a-zA-Z \- ]*[a-zA-Z]$/,
    error_message:
      "The last name should at least be 2 characters, alphabetic and not end with non alphabetic characters.",
  },
  {
    id: "email",
    title: "Email",
    placeholder: "johndoe@konekte.co",
    type: "email",
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    error_message: "Please, input a correct email address.",
  },
  {
    id: "password1",
    title: "Password",
    placeholder: "$sdf04nf0@~(83d",
    type: "password",
    pattern:
      /^(?=.*[0-9])(?=.*[!@#$%^&-*])[a-zA-Z0-9!@#$%^&-*]{8,32}$/,
    error_message:
      "The password should be stronger, at least 8 characters, 1 upper case letter and a special character (!@#$%^&*-)",
  },
  {
    id: "password2",
    title: "Retype password",
    placeholder: "************",
    type: "password",
    pattern:
      /^(?=.*[0-9])(?=.*[!@#$%^&-*])[a-zA-Z0-9!@#$%^&-*]{8,32}$/,
    error_message: "Please, make sure to write it right.",
  },
];

export const units_log_in: InputUnitProps[] = [
  {
    id: "email",
    title: "Email",
    placeholder: "johndoe@konekte.co",
    type: "email",
    error_message: "Please, type a correct email address.",
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  {
    id: "password",
    title: "Password",
    placeholder: "$sdf04nf0@~(83d",
    type: "password",
    error_message: "The password is not correct.",
    pattern:
      /^(?=.*[0-9])(?=.*[!@#$%^&-*])[a-zA-Z0-9!@#$%^&-*]{8,32}$/,
  },
];

export const making_transaction: InputUnitProps[] = [
  {
    id: "email",
    title: "Receiver info",
    placeholder: "johndoe@gmail.com",
    subtitle: "Email",
    type: "email",
    error_message: "Please, type a correct email address.",
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  {
    id: "amount",
    title: "General info",
    subtitle: "Amount",
    placeholder: "100.00",
    type: "number",
    error_message: "We temporarily stop all transactions, please try again later.",
      // "The minimum amount is $5. The maximum amount you can send is $100. Make sure you enter a valid amount.",
    pattern: /^(\d{1,4})(?:\.\d)[\d]{0,1}$/,
    validator_func: (e : React.ChangeEvent<HTMLInputElement>) => {
      // if ((parseFloat(e.target.value) <= 100) && parseFloat(e.target.value) >= 5) return true
      return false
    }
  },
  {
    id: "message",
    title: "Message",
    placeholder: "Just adding some value to your life!",
    type: "text",
    error_message: "The maximum length is 64 characters",
    pattern: /^.{1,64}$/,
  },
];

export const email_password_incorrect: string =
  "We couldn't find a combination for the email and the password provided. Try again with a different combination.";

export const signup_error: string =
  "An error occured while signing you up, please, check the data and try again.";

export const transaction_error: string =
  "Your transaction failed for some reason that we are not in measure to enumerate here. Please contact our customer service if you think that’s an error.";


export const share_text : string = "🇺🇸 Hey dear, Incredible news! I just found out an easier way to make money transaction. Join me there, at Konekte!\n 🇫🇷 Salut cher(e),\n J'ai une très bonne nouvelle! J'ai trouvé un meilleur moyen d'envoyer de l'argent. Rejoins-moi sur Konekte! https://konekte.co"