import React, { Dispatch, SetStateAction } from "react";
// styled-components
import styled from "styled-components";
import { InitialValueTransaction } from "../Sending";
import { InitialValueTrack } from "../Track";
import { InitialValueWithdrawal } from "../Transaction/WithdrawalRequest";

export type InitialValueFormAuth = {
  firstname?: string;
  lastname?: string;
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
};

export type State = Object &
  InitialValueFormAuth &
  InitialValueTransaction &
  InitialValueTrack & {
    [key: string]: string | number | undefined;
  };

export type InputUnitProps = {
  [key: string]: string | undefined | Object | boolean;
  title?: string;
  subtitle?: string;
  placeholder?: string;
  state?: State;
  stateSetter?:
    | Dispatch<SetStateAction<InitialValueFormAuth>>
    | Dispatch<SetStateAction<InitialValueTransaction>>
    | Dispatch<SetStateAction<InitialValueWithdrawal>>
    | Dispatch<SetStateAction<InitialValueTrack>>;
  type?: string;
  required?: boolean;
  id?: string;
  pattern?: RegExp;
  error_message?: string;
};

const InputUnit: React.FC<InputUnitProps> = ({
  title,
  subtitle,
  placeholder,
  state,
  stateSetter,
  type,
  required,
  id,
  pattern,
  validator_func,
  error_message,
}): JSX.Element => {
  const [valid, setValid] = React.useState(0);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    const name = id;
    const value = e.target.value;

    if (validator_func && typeof validator_func === "function") {
      const res = validator_func(e);
      if (res) setValid(1) 
      else setValid(2)
    } else {
      if (pattern?.test(value)) {
        setValid(1)
      } else {
        setValid(2)
      }
    }

    if (stateSetter)
      // @ts-ignore
      stateSetter({ ...state, [name]: value });
  };

  const minLength = type === "password" ? 8 : 1;
  const maxLength =
    type === "email" || id === "message" ? 64 : 32;

  return (
    <SInputUnit>
      <STextsInput>
        {title && <STitleInput>{title}</STitleInput>}
        {subtitle && (
          <SSubtitleInput>{subtitle}</SSubtitleInput>
        )}
      </STextsInput>
      <SInput
        // @ts-ignore
        value={state && state[id]}
        placeholder={placeholder ? placeholder : ""}
        onChange={handleChange}
        type={type}
        required={required || true}
        minLength={minLength}
        maxLength={maxLength}
        optional={{ valid: valid }}
      />
      {valid === 2 && (
        <SErrorMessage>{error_message}</SErrorMessage>
      )}
    </SInputUnit>
  );
};

const SInputUnit = styled.div``;

const STextsInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding-bottom: 1em; ;
`;

const STitleInput = styled.p`
  font-weight: 500;
`;

const SSubtitleInput = styled.p`
  font-weight: 400;
  font-size: 0.9em;
`;

const SErrorMessage = styled.p<InputProps>`
  color: ${(props) => props.theme.colors.red};
  padding-left: 0.5em;
  padding-top: 0.5em;
  font-size: 0.9em;
`;

type InputProps = {
  optional?: {
    valid: number;
  };
};

const SInput = styled.input<InputProps>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray};
  border: 2px solid
    ${(props) =>
      props.optional?.valid === 0
        ? props.theme.colors.gray
        : props.optional?.valid === 1
        ? props.theme.colors.green
        : props.theme.colors.red};
  border-radius: ${(props) => props.theme.borderRadius};
  height: 3.5em;
  padding-left: 1em;
  font-size: 1em;
`;

export default InputUnit;
