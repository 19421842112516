import React from "react";
// styled-components
import styled from "styled-components"
import { InputUnitProps } from "./InputField";

const SelectUnit : React.FC<InputUnitProps> = ({
  stateSetter, state
}) : JSX.Element => {

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const name = "bank";
    const value = e.target.value;

    if (stateSetter)
      // @ts-ignore
      stateSetter({ ...state, [name]: value });
  }

  return (
    <div>
      <SSelectTitle>Choose a bank</SSelectTitle>
      <SSelect onChange={handleChange}>
        <option value={state && state["bank"]} defaultValue={state && state["bank"]}>
          Tinkoff
        </option>
        <option value="Sberbank">Sberbank</option>
      </SSelect>
    </div>
  )
}

const SSelect = styled.select`
  width: 100%;
  height: 4em;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.gray};
  border: none;
  font-size: 0.9em;
  padding: 0 0.5em;
`;

const SSelectTitle = styled.p`
  font-weight: 500;
  margin-bottom: 1em;
`;

export default SelectUnit;