import styled from "styled-components";

export const SCheckout = styled.div`
  padding: 0 1em 4em 1em;
  width: 100%;
`;

export const SField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  margin-bottom: 2.5em;
`;

export const SFieldTitle = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  margin-bottom: 0.5em;
  `;

export const SFieldElement = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SFieldElementBold = styled.p`
  font-weight: 500;
  color: ${(props) => props.theme.colors.dark};
`;

export const SFieldElementNormal = styled.p`
  color: ${(props) => props.theme.colors.dark};
`;
