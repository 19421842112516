import styled from "styled-components";
import { StyleProps } from "./types";

export const STitleContainer = styled.div`
  display: flex;
  gap: 0.5em;
`;

export const SAdminLabelContainer = styled.div`
  background-color: ${props => props.theme.colors.green};
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius};
`;

export const SAdminLabel = styled.p`
  color: ${props => props.theme.colors.main};
`;

export const SHeader = styled.div<StyleProps>`
  height: 10vh;
  width: inherit;
  background-color: ${(props) =>
    props.optional?.isMenu
      ? props.theme.colors.white
      : props.theme.colors.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  top: 0;
  right: 0;
  left: 0;
  position: sticky;
`;

export const STitle = styled.p<StyleProps>`
  color: ${(props) =>
    props.optional?.isMenu
      ? props.theme.colors.main
      : props.theme.colors.white};
  font-size: 1.4em;
  font-family: "Montserrat Alternates", "Work Sans";
  font-weight: ${(props) => (props.optional?.isMenu ? 500 : 400)};
`;

export const SMenu = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  left: 0;
  right: 0;
  top: 10vh;
  height: 90vh;
  padding: 1em;
  justify-content: space-between;
  overflow-y: scroll;
`;

export const SMenuOptions = styled.div`
  padding-bottom: 1em;
`;

export const SMenuOption = styled.div`
  padding: 1.3em 0.5em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
`;

export const STitleOption = styled.p`
  font-size: 1.2em;
`;

export const STextOption = styled.p`
  font-size: 0.95em;
  color: rgba(0, 0, 0, 0.5);
`;

export const SLabelOption = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  position: absolute;
  left: 5em;
  padding: 0.8em 1.3em;
  border-radius: ${(props) => props.theme.borderRadius};

  & > p {
    color: ${(props) => props.theme.colors.white};
    font-weight: 300;
  }
`;

export const SMission = styled.div`
  padding: 1em;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  flex-direction: column;
  gap: 1em;

  & > :first-child {
    line-height: 1.5em;
    color: ${(props) => props.theme.colors.dark};
    font-size: 0.9em;
  }

  & > :last-child {
    font-weight: 600;
  }
`;