import React, { Dispatch, SetStateAction } from "react";
// styled-components
import styled from "styled-components";
// types
import { WithdrawalRequestData } from "./types";
// utils
import { currencyDisplayer } from "../../utils";
// components
import InputUnit from "../utils/InputField";
import SelectUnit from "../utils/SelectField";
import GetUser from "../utils/GetUser";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context";

export type InitialValueWithdrawal = {
  bank: string;
  phoneNumber: string;
  cardNumber: string;
}

type WithdrawalRequestProps = {
  withdrawalData: WithdrawalRequestData;
  setRequestingWithdrawal: Dispatch<SetStateAction<boolean>>;
  setWithdrawalRequestPlaced: Dispatch<SetStateAction<boolean>>;
};

const WithdrawalRequest: React.FC<WithdrawalRequestProps> = ({
  withdrawalData,
  setRequestingWithdrawal,
  setWithdrawalRequestPlaced,
}): JSX.Element => {

  const initialValue : InitialValueWithdrawal = {
    bank: "Tinkoff",
    phoneNumber: "+",
    cardNumber: "",
  }

  const [withdrawalObjData, setWithdrawalObjData] = React.useState(initialValue)
  const navigate = useNavigate()

  let accessToken = localStorage.getItem("user")

  const { user } = React.useContext(Context);

  const handleWithdraw = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    // API call
    const url =
      process.env.REACT_APP_BASE_URL +
      `/transaction/take-step?target=requested&code=${withdrawalData.transactionCode}`;
    const data = { 
      time: Date.now(),
      requestDetails: withdrawalObjData,
      handlerEmail: user.email,
    };
    let token = accessToken;
    token = token && JSON.parse(token);
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.put(url, data, options);
      // console.log(response);
      setRequestingWithdrawal(false);
      setWithdrawalRequestPlaced(true);
      navigate(0);
    } catch (error) {
      console.error(error);
    }
    console.log(withdrawalObjData)
  };

  return (
    <SRequestingWidrawal>
      <GetUser />
      <p>Withdrawal request</p>
      <SForm onSubmit={handleWithdraw}>
        <SelectUnit 
          state={withdrawalObjData} stateSetter={setWithdrawalObjData}
        />
        <InputUnit 
          title="Phone / Card number" 
          placeholder="+7 900 000 00 00"
          state={withdrawalObjData}
          stateSetter={setWithdrawalObjData}
          type="text"
          id="phoneNumber"
          error_message="Please, enter a valid phone number."
          pattern={/^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/}  // TODO make it stronger
        />
        <div>
          <SRateTitle>Rate</SRateTitle>
          <SRate>
            <p>
              {withdrawalData.sellingRate + "RUB / " + withdrawalData.currency}
            </p>
          </SRate>
        </div>
        <p>
          {"You will receive: " +
            currencyDisplayer(
              withdrawalData.amount! * withdrawalData.sellingRate!,
              "RUB",
            )}
        </p>
        <SWithdrawButton type="submit" value="Withdraw" />
      </SForm>
    </SRequestingWidrawal>
  );
};

const SRequestingWidrawal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.3em;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};

  & > :first-child {
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const SRate = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.3em;
  color: ${(props) => props.theme.colors.main};
`;

const SRateTitle = styled.p`
  font-weight: 500;
  margin-bottom: 1em;
`;

const SWithdrawButton = styled.input`
  display: flex;
  gap: 0.5em;
  padding: 1em 2em;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: ${(props) => props.theme.borderRadius};
  color: #fff;
  width: fit-content;
  align-self: flex-end;
  border: none;
`;

export default WithdrawalRequest;
