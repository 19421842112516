import React from "react";
import { Context } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const GetUser: React.FC = (): JSX.Element => {

  const navigate = useNavigate();
  const location = useLocation();

  const isStart = location.pathname === "/start";
  const isSignUp = location.pathname === "/sign-up";
  const isEntry = location.pathname === "/";

  // @ts-ignore
  const { user, setUser } = React.useContext(Context);

  React.useEffect(() => {
    (async function () {
      if (Object.keys(user).length === 0) {
        // try to read from local storage
        const localStorageUser = localStorage.getItem("user");
        if (!localStorageUser) {
          !isSignUp && !isEntry && navigate("/login", { replace: true });
        } else {
          const url = process.env.REACT_APP_BASE_URL + `/user`;
          const storedUser = JSON.parse(localStorageUser);
          const token = storedUser;
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          try {
            const fetchedUser = await axios.get(url, options);
            // @ts-ignore
            setUser(fetchedUser.data.data[0]);
          } catch (error) {
            console.error(error);
            // @ts-ignore
            if ((error.response.data.msg === "Failed" || error.code === "ERR_BAD_REQUEST") && !isEntry && !isSignUp)
              navigate("/login", { replace: true });
          }
        }
      }
    })();
  }, [user, setUser, navigate, isStart, isSignUp, isEntry]);

  return <></>;
};

export default GetUser;
