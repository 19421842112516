import React from "react";
// styled-components
import styled from 'styled-components';
// react-icons
import {HiExternalLink} from "react-icons/hi";
// texts
import { entry_text } from "../../data/texts/entry";
// components
import SVG from "./Empty";
import { useNavigate } from "react-router-dom";

const Overview : React.FC = () : JSX.Element => {
  const navigate = useNavigate()

  const onStartClick = () => {
    let user = localStorage.getItem("user");
    if(!user) navigate("/login")
    else {
      navigate("/start", {replace: true})
    }
  }

  return (
    <Container>
      <SSVG>
        <SVG />
      </SSVG>
      <MissionText>{entry_text.overview}</MissionText>
      <StartNow onClick={() => onStartClick()}>
        <StartNowText>Start Now</StartNowText>
      </StartNow>
      <WarningText>{entry_text.warning} <HiExternalLink size="1.4em"/> </WarningText>
    </Container>
  )
}

const Container = styled.div`
  height: fit-content;
  width: inherit;
  background-color: ${props => props.theme.colors.main};
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2em;
`;

const MissionText = styled.p`
  font-size: 1.7em;
  line-height: 45px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`;

const StartNow = styled.div`
  width: 12em;
  height: 3.5em;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.borderRadius};
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartNowText = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 1.3em
`;

const WarningText = styled.p`
  color: ${props => props.theme.colors.white}; 
  line-height: 24px;
  font-weight: 300;
  font-size: 0.9em;
`;

const SSVG = styled.div`
  align-self: center;
  margin-bottom: 3em;
  margin-top: 7vh;
`

export default Overview;